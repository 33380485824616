import React, { useEffect, useState } from 'react';
import * as S from './../newsCard.styles';

const X2tkNews: React.FC = () => {
  const [newsItems, setNewsItems] = useState([]);

  // Fetching the JSON data
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://bbf.noilai.com/news?format=json');
        if (!response.ok) {
          throw new Error('Failed to fetch news');
        }
        const data = await response.json();
        setNewsItems(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchNews();
  }, []);

  return (
    <S.NewsWrapper>
      {newsItems.length === 0 ? (
        <p>Loading news...</p>
      ) : (
        <ul>
          {newsItems.map(({ title, date, content }, index) => (
            <li key={index} className="news-item">
              <h3>
                {title}
                <br />
                <small>{date}</small>
              </h3>
              <S.Description
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              ></S.Description>
            </li>
          ))}
        </ul>
      )}
    </S.NewsWrapper>
  );
};

export default X2tkNews;
