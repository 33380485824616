import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './RecoStockTable.styles';
import { RecoStocks, results } from '@app/constants/recoStocks';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

export const RecoStockTable: React.FC = () => {
  const { isTablet, isDesktop } = useResponsive();
  const { t } = useTranslation();
  const [dataSource] = useState<RecoStocks[]>(results);

  const navigate = useNavigate();

  const columns: ColumnsType<RecoStocks> = [
    {
      title: t('x2tk.symbol'),
      dataIndex: 'symbol',
      // width: '30%',
      render: (symbol: string) => <S.Text>{symbol}</S.Text>,
    },
    {
      title: t('x2tk.industry'),
      dataIndex: 'industry',
      render: (industry: string) => <S.Text>{industry}</S.Text>,
    },
    {
      title: t('x2tk.entryPrice'),
      dataIndex: 'entryPrice',
      render: (entryPrice: number) => <S.Text>{entryPrice}</S.Text>,
    },
    {
      title: t('x2tk.closePrice'),
      dataIndex: 'closePrice',
      render: (closePrice: number) => <S.Text>{closePrice}</S.Text>,
    },
    {
      title: t('x2tk.targetPrice'),
      dataIndex: 'targetPrice',
      render: (targetPrice: number) => <S.Text>{targetPrice}</S.Text>,
    },
    {
      title: t('x2tk.cutlossPrice'),
      dataIndex: 'cutlossPrice',
      render: (cutlossPrice: number) => <S.Text>{cutlossPrice}</S.Text>,
    },
    {
      title: t('x2tk.weight'),
      dataIndex: 'weight',
      render: (weight: number) => <S.Text>{weight} %</S.Text>,
    },
  ];

  return (
    <S.Table
      size={'small'}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      scroll={{ y: isDesktop ? 300 : isTablet ? 620 : 400 }}
      onRow={(record) => ({
        onClick: () => {
          navigate('/technical?' + record.symbol); // Navigate to the path on row click
        },
      })}
    />
  );
};
