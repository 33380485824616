export interface RecoStocks {
  symbol: string;
  industry: string;
  entryPrice: number;
  exitPrice: number;
  weight: number;
  closePrice: number;
  targetPrice: number;
  cutlossPrice: number;
}

export const results: RecoStocks[] = [
  {
    symbol: 'DXG',
    industry: 'Real Estate',
    entryPrice: 15.1,
    exitPrice: 19.8,
    weight: 45,
    closePrice: 15.7,
    targetPrice: 19.8,
    cutlossPrice: 14.7,
  },
  {
    symbol: 'VEA',
    industry: 'Automobile',
    entryPrice: 38.5,
    exitPrice: 43.2,
    weight: 55,
    closePrice: 41.3,
    targetPrice: 43.2,
    cutlossPrice: 37.6,
  },
].map((result, index) => ({ ...result, key: index }));
