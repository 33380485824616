import React from 'react';
import * as S from './MarketPulseCard.styles';
import { NFTCardHeader } from '@app/components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { useTranslation } from 'react-i18next';

export const MarketPulseCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <NFTCardHeader title={t('x2tk.marketPulse')}></NFTCardHeader>
      <S.SectionWrapper>
        <S.MarketPulseCard padding={0}>
          <S.Description>
            05/05/2025: Vnindex có 1 phiên giao dịch tiêu cực với lực bán lớn, xuyên thủng ma20 và chạm biên dưới của
            dãy bolinger band. Trong các phiên tới sẽ có nhịp phục hồi backtest lại vùng 1.260, đó là lúc phù hợp để cơ
            cấu lại danh mục. Không cần phải bán hoảng loạn nhé!
          </S.Description>
        </S.MarketPulseCard>
      </S.SectionWrapper>
    </>
  );
};
