import React from 'react';
import * as S from './newsCard.styles';
import { NFTCardHeader } from '@app/components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { useTranslation } from 'react-i18next';
import X2tkNews from '@app/components/x2tk-dashboard/newsCard/X2tkNews/X2tkNews';

export const NewsCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <NFTCardHeader title={t('x2tk.news')}></NFTCardHeader>
      <S.SectionWrapper>
        <S.MarketPulseCard padding={0}>
          <X2tkNews />
        </S.MarketPulseCard>
      </S.SectionWrapper>
    </>
  );
};
