import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { References } from '@app/components/common/References/References';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { RecoStockCard } from '@app/components/x2tk-dashboard/recoStockCard/RecoStockCard/RecoStockCard';
import { MarketPulseCard } from '@app/components/x2tk-dashboard/marketPulseCard/MarketPulseCard/MarketPulseCard';
import { NewsCard } from '@app/components/x2tk-dashboard/newsCard/newsCard';
import { WatchList } from '@app/components/x2tk-dashboard/watchList/WatchList';

const MedicalDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <BaseRow gutter={[60, 60]}>
          <BaseCol span={24}>
            <div id="market-pulse">
              <MarketPulseCard />
            </div>
          </BaseCol>

          <BaseCol span={24}>
            <div id="reco-stock">
              <RecoStockCard />
            </div>
          </BaseCol>

          <BaseCol span={24}>
            <div id="x2tk-news">
              <NewsCard />
            </div>
          </BaseCol>
        </BaseRow>
        <References />
      </S.LeftSideCol>

      <S.RightSideCol xl={8} xxl={7}>
        <div id="watchlist">
          <WatchList />
        </div>
      </S.RightSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div id="market-pulse-card">
          <MarketPulseCard />
        </div>
      </BaseCol>
      <BaseCol span={24}>
        <div id="reco-stock-card">
          <RecoStockCard />
        </div>
      </BaseCol>
      <BaseCol span={24}>
        <div id="watchlist">
          <WatchList />
        </div>
      </BaseCol>
      <BaseCol span={24}>
        <div id="x2tk-news">
          <NewsCard />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MedicalDashboardPage;
