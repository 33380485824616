/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useImperativeHandle } from 'react';
import { BaseForm, BaseFormInterface, BaseFormProps } from '@app/components/common/forms//BaseForm/BaseForm';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseFormTitle } from '@app/components/common/forms/components/BaseFormTitle/BaseFormTitle';
import { BaseFormItem } from '@app/components/common/forms/components/BaseFormItem/BaseFormItem';
import { BaseFormList } from '@app/components/common/forms/components/BaseFormList/BaseFormList';
import { Form } from 'antd';
import { FormInstance } from 'antd/es';

export interface BaseButtonsFormProps extends BaseFormProps {
  isFieldsChanged: boolean;
  setFieldsChanged?: (state: boolean) => void;
  footer?: React.ReactElement;
  loading?: boolean;
}

export interface BaseRefFormInterface extends React.ForwardRefExoticComponent<any> {
  Title: typeof BaseFormTitle;
  Item: typeof BaseFormItem;
  List: typeof BaseFormList;
  useForm: typeof Form.useForm;
  Provider: typeof Form.Provider;
}

export const BaseButtonsForm: BaseFormInterface<BaseButtonsFormProps> = ({
  form,
  isFieldsChanged,
  setFieldsChanged,
  footer,
  loading = false,
  children,
  ...props
}) => {
  const [formDefault] = BaseForm.useForm();
  const currentForm = form || formDefault;

  const onCancel = () => {
    currentForm?.resetFields();
    setFieldsChanged && setFieldsChanged(false);
  };

  return (
    <BaseForm form={currentForm} {...props}>
      {children}
      {isFieldsChanged && (footer || <BaseButtonsGroup loading={loading} onCancel={onCancel} />)}
    </BaseForm>
  );
};

export interface BaseButtonsRefFormRefs {
  form: FormInstance<unknown>;
}

export const BaseButtonsRefForm = forwardRef<BaseButtonsRefFormRefs, BaseButtonsFormProps>(
  ({ form, isFieldsChanged, setFieldsChanged, footer, loading = false, children, ...props }, ref) => {
    const [formDefault] = BaseForm.useForm();
    const currentForm = form || formDefault;

    const onCancel = () => {
      currentForm?.resetFields();
      setFieldsChanged && setFieldsChanged(false);
    };

    const publicRef = {
      form: currentForm,
    };

    useImperativeHandle(ref, () => {
      return publicRef;
    });

    return (
      <BaseForm form={currentForm} {...props}>
        {children}
        {isFieldsChanged && (footer || <BaseButtonsGroup loading={loading} onCancel={onCancel} />)}
      </BaseForm>
    );
  },
) as BaseRefFormInterface;

BaseButtonsForm.Title = BaseFormTitle;
BaseButtonsForm.Item = BaseFormItem;
BaseButtonsForm.List = BaseFormList;
BaseButtonsForm.useForm = BaseForm.useForm;
BaseButtonsForm.Provider = BaseForm.Provider;

BaseButtonsRefForm.Title = BaseFormTitle;
BaseButtonsRefForm.Item = BaseFormItem;
BaseButtonsRefForm.List = BaseFormList;
BaseButtonsRefForm.useForm = BaseForm.useForm;
BaseButtonsRefForm.Provider = BaseForm.Provider;
