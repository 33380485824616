import React from 'react';
import { RecoStockTable } from '../RecoStockTable/RecoStockTable';
import * as S from './RecoStockCard.styles';
import { NFTCardHeader } from '@app/components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { useTranslation } from 'react-i18next';

export const RecoStockCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <NFTCardHeader title={t('x2tk.recommendedStocks')}></NFTCardHeader>
      <S.SectionWrapper>
        <S.RecoStockCard padding={0}>
          <RecoStockTable />
        </S.RecoStockCard>
      </S.SectionWrapper>
    </>
  );
};
